export const environment = {
	production: false,
	apiUrl: 'https://desarrollo-api.plenion247.eu/api/',
	b2cUrl: 'https://dev-api2.plenion247.eu/b2c/v1',
	apiPlenion: 'https://dev-api2.plenion247.eu/api/v1',
	apiPlenionKey: 'AIzaSyBZcmahbUek-TLyOjX5WHhnNVEDmfoe2nM',
	apiUrlHost: 'https://dev-api-services.plenion247.eu',
	mapsApiKey: 'AIzaSyCghRwR3TJhyUa7rGenei2qmcUGEj9m3n8',
	portalUrl: 'https://admin.dev-app.plenion247.eu',
	version: '0.9.4'
};
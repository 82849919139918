import { NgModule } from '@angular/core';
import { TableModule } from 'primeng/table';
import { InputTextModule } from 'primeng/inputtext';
import { MessagesModule } from 'primeng/messages';
import { CheckboxModule } from 'primeng/checkbox';
import { RadioButtonModule } from 'primeng/radiobutton';
import { CardModule } from 'primeng/card';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ToastModule } from 'primeng/toast';
import { ButtonModule } from 'primeng/button';
import { ScrollPanelModule } from 'primeng/scrollpanel';

@NgModule({
	exports: [
		TableModule, 
		InputTextModule, 
		MessagesModule,
		CheckboxModule,
		RadioButtonModule,
		CardModule,
		ConfirmDialogModule,
		ToastModule,
		ButtonModule,
		ScrollPanelModule
	],
	declarations: [],
})
export class PrimeModule {}

import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { MapViewComponent } from './map-view.component';
import { PrimeModule } from 'src/app/shared/prime.module';
import { CommonModule } from '@angular/common';
import { InputSwitchModule } from 'primeng/inputswitch';
import { FormsModule } from '@angular/forms';
import { PanelModule } from 'primeng/panel';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MaterialModule } from 'src/app/shared/material.module';

@NgModule({
	imports: [
		RouterModule,
		PrimeModule,
		MaterialModule,
		CommonModule,
		InputSwitchModule,
		FormsModule,
		PanelModule,
		BrowserAnimationsModule
	],
	declarations: [MapViewComponent],
	exports: [MapViewComponent],
})
export class MapViewModule {}

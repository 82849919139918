import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { RouteResponse } from "../models/route.model";

@Injectable({
	providedIn: 'root',
})
export class RoutesService {
    private apiUrl: string = environment.apiUrl;
    private apiKey: String = environment.apiPlenionKey;
    private apiServicesUrl: string = environment.apiUrlHost;

    constructor(private http: HttpClient) {}

    getRoutes(namespace: String, routesID: String) {
        const url = `${this.apiUrl}routes/v0.1/route/${namespace}?routes=${routesID}&key=` + this.apiKey;

		return this.http.get<RouteResponse>(url);
    }

    updateRoutingCredits(domain: String) {
        const url = `${this.apiServicesUrl}/UpdateRoutingCredits?domain=${domain}`;

        return this.http.get(url);
    }
}

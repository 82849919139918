import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MapViewModule } from "./components/map-view/map-view.module";
import { HttpClientModule } from '@angular/common/http';
import { RoutesService } from './services/routes.service';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

@NgModule({
    declarations: [
        AppComponent
    ],
    providers: [
        RoutesService
    ],
    bootstrap: [AppComponent],
    imports: [
        HttpClientModule,
        BrowserAnimationsModule,
        BrowserModule,
        AppRoutingModule,
        MapViewModule
    ]
})
export class AppModule { }

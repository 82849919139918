export class GlobalConstants {
  public static colors: string[] = [
    '#FF0000', // Red
    '#008B8B', // DarkCyan
    '#7FFF00', // Chartreuse
    '#FF7F50', // Coral
    '#6495ED', // CornflowerBlue
    '#DC143C', // Crimson
    '#B8860B', // DarkGoldenRod
    '#A9A9A9', // DarkGray
    '#FF7F00', // Orange
    '#FFFF00', // Yellow
    '#00FF00', // Lime
    '#00FFFF', // Aqua
    '#D2691E', // Chocolate
    '#00FFFF', // Cyan
    '#00008B', // DarkBlue
    '#0000FF', // Blue
    '#8A2BE2', // BlueViolet
    '#A52A2A', // Brown
    '#DEB887', // BurlyWood
    '#5F9EA0', // CadetBlue
  ];
}

import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Route, RouteResponse } from 'src/app/models/route.model';
import { RoutesService } from 'src/app/services/routes.service';
import { Loader } from '@googlemaps/js-api-loader';
import { GlobalConstants } from 'src/app/utils/global-constants';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-map-view',
    standalone: false,
    templateUrl: './map-view.component.html',
    styleUrl: './map-view.component.scss'
})
export class MapViewComponent implements OnInit, OnDestroy {
    @ViewChild('googleMap', { static: true })
    routeKeys: any[] = [];
    routeKeys2: any = {};
    unsubscriber$: Subject<any> = new Subject();

    googleMap!: any;

    map!: google.maps.Map;
    mapLoaded: boolean = false;
    loadingRoutes: boolean = true;
    routes!: RouteResponse;
    routesDisplayed: any[] = [];
    namespace: String = "";
    myRoutes: String = "";

    renderers: any = [];
    visibility: any = [];

    directions: any;

    constructor(
        private router: Router,
        private activeRoute: ActivatedRoute,
        private routeService: RoutesService,
    ) { }
    ngOnDestroy(): void {
        this.unsubscriber$.unsubscribe();
    }

    ngOnInit(): void {
        this.routeKeys = [    
            "origin",
            "destination",
            "startTime",
            "distance",
            "totalTime",
            "idealTotalTime",
            "isOptimized",
            "waypoints"
        ]

        this.routeKeys2 = {    
            "origin": "Origin",
            "destination": "Destination",
            "startTime": "Start Time",
            "distance": "Distance",
            "totalTime": "Total Time",
            "idealTotalTime": "Ideal Total Time",
            "isOptimized": "Is Optimized",
            "waypoints": "Waypoints"
        }
        

        this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                const currentUrl = this.router.url;
                const urlTree = this.router.parseUrl(currentUrl);
                const urlParams = urlTree.root.children['primary'].segments;

                this.namespace = urlParams[1]?.path;
                this.myRoutes = urlParams[2]?.path;

                this.routeService.getRoutes(this.namespace, this.myRoutes).subscribe((response) => {
                    this.routes = response;

                    for (let i = 0; i < this.routes.items.length; i++){
                        this.routes.items[i]['visibility'] = true;
                    }

                    this.routesDisplayed = this.routes.items;
                    this.loadingRoutes = false;

                    this.mapLoaded = true;
                    // console.log(this.routes.items[0]['myDirectionJSON']);
                    // this.directions = this.routes.items[0]['myDirectionJSON'];            

                    const loader = new Loader({
                        apiKey: environment.mapsApiKey,
                        version: 'weekly',
                    });

                    loader.importLibrary('maps')
                        .then(({ Map }) => {
                            this.map = new Map(document.getElementById("routes-map") as HTMLElement, {
                                zoom: 30,
                                center: { lat: -3.6850321, lng: -37.17351695 },
                            });
                            this.initMap();
                        })
                        .catch((e) => { });
                });
            }
        });

    }


    initMap(): void {
        for (let i = 0; i < this.routes.items.length; i++) {
            var directionsRenderer = new google.maps.DirectionsRenderer({
                suppressMarkers: false,
                polylineOptions: {
                    strokeColor: GlobalConstants.colors[i],
                    strokeOpacity: 0.7,
                }
            });
            directionsRenderer.setMap(this.map);

            const jsonObject = JSON.parse(this.routes.items[i]['myDirectionJSON']);
            const directionsResult: google.maps.DirectionsResult = jsonObject as google.maps.DirectionsResult;

            directionsRenderer.setDirections(directionsResult);

            this.renderers.push(directionsRenderer);
            this.visibility.push(true);
        }

        // this.routeService.updateRoutingCredits(this.namespace).subscribe((response) => {});

        // directionsRenderer.setOptions({suppressMarkers: true, polylineOptions: {visible: false}})
    }

    changeVisibility(index: number): void {
        let currentVisibility = this.visibility[index];
        // this.renderers[index].setOptions({ suppressMarkers: currentVisibility, polylineOptions: { visible: !currentVisibility } })
        // this.renderers[index].setMap(this.map);

        if (currentVisibility)
            this.renderers[index].setMap(null);
        else
            this.renderers[index].setMap(this.map);

        this.visibility[index] = !currentVisibility;
        this.routes.items[index]['visibility'] = !this.routes.items[index]['visibility'];

    }

    getFormattedTime(seconds: number): string {
        let hours = Math.floor(seconds / 3600);
        let minutes = Math.floor((seconds % 3600) / 60);

        let time = minutes + "m";
        if (hours > 0)
            time = hours + "h " + minutes + "m";


        return time;
    }

    getFormattedDistance(meters: number): string {
        return (meters / 1000).toFixed(2) + " km"
    }

    getRouteColor(index: number): any {
        return GlobalConstants.colors[index];
    }

    formatDateString(dateString: string): string {
        const date = new Date(dateString);
        return `${date.getDate().toString().padStart(2, '0')}/${(date.getMonth() + 1).toString().padStart(2, '0')}/${date.getFullYear()} ${date.getHours().toString().padStart(2, '0')}:${date.getMinutes().toString().padStart(2, '0')}`;
    }

    navigateTo(url: string) {
        window.open(url, "_blank");
    }

    getWaypointLetter(index: number): string {
        return String.fromCharCode(66 + index); //66 es el codigo ASCII de B
    }
}

<div class="map-view-cards">
    <div class="map-view">
        <p-card>
            <div #googleMap id="routes-map"></div>
        </p-card>
    </div>
    <div>
        <div *ngIf="mapLoaded" #mapUtilities id="map-utilities">
            <div *ngFor="let route of routesDisplayed; let i = index" styleClass="ui-card-shadow">
                <p-panel [toggleable]="true" [collapsed]="true">
                    <ng-template pTemplate="header">
                        <div style="width: 15px; height: 15px; margin-right: 5px;"  [style.background-color]="getRouteColor(i)"></div>
                        <div>
                            <b>{{ route.label }}</b> 
                            @if(route.isOptimized){✔}
                            @else{❌}
                        </div>
                    </ng-template>
                    <ng-template pTemplate="icons">
                        <mat-icon class="p-panel-header-icon p-link mr-2" (click)="navigateTo(route.mapsURL)">
                            map
                        </mat-icon>
                        <mat-icon class="p-panel-header-icon p-link mr-2" (click)="changeVisibility(i)">
                            {{ route.visibility ? 'visibility' : 'visibility_off' }}
                        </mat-icon>
                    </ng-template>
                    
                    <div *ngFor="let key of routeKeys;">
                        <div>
                        @if(key !== 'waypoints') {
                            <b>{{ routeKeys2[key] }}:</b> 
                            @if(key === 'distance') {
                                {{ getFormattedDistance(route[key]) }}
                            }@else if(key === 'totalTime' || key === 'idealTotalTime') {
                                {{ getFormattedTime(route[key]) }}
                            }@else if(key === 'isOptimized'){
                                @if(route[key]){✔}
                                @else{❌}
                            }@else if(key === 'startTime') {
                                {{ formatDateString(route[key]) }}
                            }@else{
                                {{ route[key] }}
                            }
                        }@else {
                            @if(route.waypoints) {
                                <p-panel [toggleable]="true" [collapsed]="true">
                                    <ng-template pTemplate="header">
                                        <b>Waypoints</b>
                                    </ng-template>
                                    <div *ngFor="let waypoint of route.waypoints; let i = index;">
                                        <p-card>
                                            <span class="circle-icon">{{ getWaypointLetter(i) }}</span> {{ waypoint.destination }} <br>
                                            <b>Distance: </b>{{ getFormattedDistance(waypoint.distance) }} <br>
                                            <b>Ideal Time: </b>{{ getFormattedTime(waypoint.idealTime) }} <br>
                                            <b>Time: </b>{{ getFormattedTime(waypoint.time) }} <br>
                                        </p-card>
                                    </div>
                                </p-panel>
                            }
                        }
                        </div>
                    </div>
                </p-panel>
            </div>
        </div>
    </div>
</div>

